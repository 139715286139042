.toolbar li {
  height: 60px;
  cursor: pointer;
  position: relative;
  background-color: #ffffff;
}

.toolbar li.group span {
  background: linear-gradient(-135deg, #444 10px, rgba(0, 0, 0, 0) 0);
  width: 60px;
  height: 50px;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 5px;
  pointer-events: none;
}

.toolbar input.toolbar-button {
  width: 60px;
  height: 60px;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0;
}

.toolbar li input.toolbar-button.selected {
  border: 1px black solid;
  background-color: #dddddd;
}

.toolbar li input.toolbar-button:not(.selected):hover {
  background-color: #eeeeee;
}

.toolbar li .subToolbar:not(.hidden) {
  height: 60px;
  position: absolute;
  display: inline;
  white-space: nowrap;
  padding: 0;
}

.toolbar li .subToolbar > li {
  display: inline-block;
}

input[type="button"].map-manager {
  background-image: url(../img/folder-svgrepo-com.svg);
  background-size: 40px 40px;
}

input[type="button"].modal-filter {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='60' height='60'%3E%3Ccircle cx='30' cy='30' r='20' stroke='green' stroke-width='3' fill='green' fill-opacity='.2' %3E%3C/circle%3E%3C/svg%3E");
}

input[type="button"].mobility-lane {
  background-image: url(../img/bicycle-svgrepo-com.svg);
  background-size: 50px 50px;
}

input[type="button"].tram-line {
  background-image: url(../img/tram-svgrepo-com.svg);
  background-size: 50px 50px;
}

input[type="button"].car-free-street {
  background-image: url(../img/ban-on-driving-147248.svg);
  background-size: 50px 50px;
}

input[type="button"].school-street {
  background-image: url(../img/school-street.svg);
  background-size: 50px 50px;
}

input[type="button"].one-way-street {
  background-image: url(../img/one-way-street.svg);
  background-size: 40px 50px;
}

input[type="button"].ltn {
  font-size: 2em;
  line-height: 56px;
}

input[type="button"].bus-gate {
  background-image: url(../img/double-decker-bus-svgrepo-com.svg);
  background-size: 50px 50px;
}

input[type="button"].traffic-lights {
  background-image: url(../img/trafficlights-black1.svg);
  background-size: 50px 50px;
}

input[type="button"].pedestrian-lights {
  background-image: url(../img/UK-Traffic-Signal-Pedestrians-1975.svg);
  background-size: 50px 50px;
}

input[type="button"].zebra-crossing {
  background-image: url(../img/zebra-crossing-svgrepo-com.svg);
  background-size: 50px 50px;
}

input[type="button"].settings {
  background-image: url(../img/settings-svgrepo-com.svg);
  background-size: 40px 40px;
}

input[type="button"].help {
  background-image: url(../img/help-svgrepo-com.svg);
  background-size: 40px 40px;
}

input[type="button"].share {
  background-image: url(../img/share-svgrepo-com.svg);
  background-size: 40px 40px;
}
