#map-manager input[type="button"] {
  width: 60px;
  height: 60px;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0;
  background-color: #ffffff;
}

#map-manager input[type="button"]:hover {
  background-color: #eeeeee;
}

input[type="button"].map-manager {
  background-image: url(../img/folder-svgrepo-com.svg);
  background-size: 40px 40px;
}

input[type="button"].save-file {
  background-image: url(../img/save-svgrepo-com.svg);
  background-size: 40px 40px;
}

input[type="button"].save-geojson-file {
  background-image: url(../img/geojson-file-svgrepo-com.svg);
  background-size: 40px 40px;
}

input[type="button"].load-file {
  background-image: url(../img/folder-svgrepo-com.svg);
  background-size: 40px 40px;
}

input[type="button"].new-map {
  background-image: url(../img/add-document-svgrepo-com.svg);
  background-size: 50px 50px;
}

input[type="button"].copy-map {
  background-image: url(../img/copy-file-svgrepo-com.svg);
  background-size: 50px 50px;
}

#map-manager ul > li {
  clear: both;
}

#map-manager ul > li:hover {
  background-color: #eeeeee;
}

#map-manager ul > li .delete-button {
  height: 30px;
  width: 30px;
}
