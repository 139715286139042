.legend {
  padding: 6px 8px;
  font: 14px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  line-height: 24px;
  color: #555;
}

.legend li {
  list-style: none;
  cursor: pointer;
}

.legend li.disabled {
  background-color: #ddd;
}

.legend h4 {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  margin: 2px 12px 8px;
  color: #777;
}

.legend span {
  position: relative;
  bottom: 3px;
}

.legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin: 0 8px 0 0;
  opacity: 0.7;
}

.legend i.icon {
  background-size: 18px;
  background-color: rgba(255, 255, 255, 1);
}

.legend .bus-gate-icon {
  background-image: url(../img/double-decker-bus-svgrepo-com.svg);
  background-size: 15px 15px;
  background-repeat: no-repeat;
  height: 15px !important;
  width: 15px !important;
}

.legend .traffic-lights-icon {
  background-image: url(../img/trafficlights-black1.svg);
  background-size: 15px 15px;
  background-repeat: no-repeat;
  height: 15px !important;
  width: 15px !important;
}

.legend .pedestrian-lights-icon {
  background-image: url(../img/UK-Traffic-Signal-Pedestrians-1975.svg);
  background-size: 15px 15px;
  background-repeat: no-repeat;
  height: 15px !important;
  width: 15px !important;
}

.legend .zebra-crossing-icon {
  background-image: url(../img/zebra-crossing.svg);
  background-size: 15px 15px;
  background-repeat: no-repeat;
  height: 15px !important;
  width: 15px !important;
}
